<script>
import appConfig from "@/app.config";
import utils from "../../../../helpers/utils";
import JsonExcel from "vue-json-excel";


/**
 * Advanced table component
 */
export default {
  props: ["telecallers_data", "filters"],
  components: { JsonExcel },
  watch: {
    telecallers_data: {
      deep: true,
      handler(v) {
        this.telecallers = v;
        this.totalRows = this.telecallers.length;
        this.getIncallStatusSummary();
      },
    },
    filters: {
      deep: true,
      handler() {
        this.getIncallStatusSummary();
      },
    },
  },
  data() {
    return {
      telecallers: [],
      telecaller_download: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "total_calls",
      sortDesc: true,
      fields: [],
      statuses: [],
      key: 0,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.telecallers.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.fields = [{ key: "username", sortable: true, label: "Digi MR" }];
    appConfig.call_statuses.forEach((s) => {
      this.fields.push({
        key: s.replaceAll("_", " "),
        sortable: true,
        label: s.replaceAll("_", " "),
      });
    });
    this.fields.push(
      {
        key: "pending_calls",
        sortable: true,
      },
      {
        key: "total_calls",
        sortable: true,
      },
      {
        key: "total_call_duration",
        sortable: true,
      },
      {
        key: "avg_call_duration",
        sortable: true,
      },
      {
        key: "total_opted_ins",
        sortable: true,
      }
    );
    this.statuses = appConfig.call_statuses;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    getFormatedTime(e) {
      return utils(e);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getIncallStatusSummary() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/callStatusesSummaries?is_field_mr=false",
          {
            params: this.filters,
          }
        );
        this.telecallers.map((t) => {
          this.fields.forEach((f) => {
            if (f.key !== "username") {
              delete t[f.key];
            }
          });
        });
        this.key += 1;
        response.data.call_statuses_summary.map((res) => {
          let index = this.telecallers.findIndex(
            (t) => t.id === res.telecaller_id
          );
          if (isNaN(this.telecallers[index].total_calls)) {
            this.telecallers[index].total_calls = 0;
            this.telecallers[index].total_call_duration = 0;
            this.telecallers[index].total_opted_ins = 0;
          }
          this.telecallers[index][res.product_call_status] = res.total_calls;
          this.telecallers[index].total_calls += parseInt(res.total_calls);
          this.telecallers[index].total_opted_ins += parseInt(res.total_opted_ins);
          this.telecallers[index].total_call_duration += parseInt(
            res.total_call_duration
          );
          this.telecallers[index].avg_call_duration =
            this.telecallers[index].total_call_duration /
            this.telecallers[index].total_calls;
          if (isNaN(this.telecallers[index].avg_call_duration)) {
            this.telecallers[index].avg_call_duration = 0;
          }
        });
        response.data.unused_allocation.map((res2) => {
          let index = this.telecallers.findIndex(
            (t) => t.id === res2.telecaller_id
          );
          this.telecallers[index].pending_calls = res2.total_count;
        });
        await this.getTelecallersDownload();
      } catch (err) {
        console.log(err);
      }
    },
    getCellColumn(f) {
      return `cell(${f.replaceAll("_", " ")})`;
    },
    getTelecallersDownload(){
      this.telecaller_download = this.telecallers.map((f) => {
        let obj = {};
        console.log(f);
        this.fields.forEach(c => {
          obj[c.key] = f[c.key] != undefined ? f[c.key] : '0'
        });
        console.log(obj);
        return obj;
      });
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Total Call Summary</h4>
          <div class="d-flex justify-content-end mb-2">
            <template v-if="telecaller_download.length > 1">
              <JsonExcel
                  class="btn"
                  :data="telecaller_download"
                  name="telecaller_call_summary.csv"
                  type="csv">
                <i class="mdi mdi-download"></i>Download
              </JsonExcel>
            </template>
            <!-- <template v-else>
              <b-button :pressed="false"  variant="primary" @click="getDownloadHistories()">Prepare Download</b-button>
            </template> -->
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="telecallers"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :key="key"
            >
              <template
                v-slot:[getCellColumn(f)]="row"
                v-for="(f, fi) in statuses"
              >
                <span v-if="row.value !== ''" :key="fi">{{ row.value }}</span>
                <span v-else :key="fi">0</span>
              </template>
              <template v-slot:cell(pending_calls)="row">
                <span v-if="row.value !== ''">{{ row.value }}</span>
                <span v-else>0</span>
              </template>
              <template v-slot:cell(total_calls)="row">
                <span v-if="row.value !== ''">{{ row.value }}</span>
                <span v-else>0</span>
              </template>
              <template v-slot:cell(total_call_duration)="row">
                <span v-if="row.value !== ''">{{
                  getFormatedTime(row.value)
                }}</span>
                <span v-else>0 sec</span>
              </template>
              <template v-slot:cell(avg_call_duration)="row">
                <span v-if="row.value !== ''">{{
                  getFormatedTime(row.value)
                }}</span>
                <span v-else>0 sec</span>
              </template>
              <template v-slot:cell(total_opted_ins)="row">
                <span v-if="row.value !== ''">{{ row.value }}</span>
                <span v-else>0</span>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
